define("discourse/plugins/SealabsOnebox/discourse/adapters/action", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _rest.default.extend({
    basePath() {
      return "/sealabs-onebox/";
    }
  });
});